import BlockScroller from "../BlockScroller";
import BlockHead from "../_BlockHead";
import ListOfItems from "./ListOfItems";
import ErrorBlock from "../ErrorBlock";
import {observer} from "mobx-react";
import {convertFlatToTree} from "../../../../utils/DataUtilities";
import classNames from 'classnames';
import styles from './styles/QuicklinkBlock.module.scss';
import {IBlockComponentProps} from "../_Block";

interface IQuicklinkBlockProps extends IBlockComponentProps {
    blockObj: IQuicklinkBlockObj
}

const QuicklinkBlock = observer((props: IQuicklinkBlockProps) => {
    try {
        const {blockType} = props.blockObj;

        const wrapperClassName = classNames({
            "sb-blockContent": true,
            "sb-block-list": true,
            "sb-sub": true,
            [styles.blockList]: true,
            [styles.sbSub]: true,
            "sb-organization-color-element-font": true,
        })

        return (
            <BlockScroller blockRef={props.blockRef}
                           blockObj={props.blockObj}
                           htmlId={props.htmlId}
                           setShowExpandButton={props.setShowExpandButton}
                           outsideGrid={props.outsideGrid}>
                <BlockHead blockType={blockType} editableTitle={props.blockObj.blockModel.title || "Quick Links"} blockId={props.blockObj.id} />
                <div className={wrapperClassName}>
                    <ul>
                        {props.blockObj.links && <ListOfItems
                            items={convertFlatToTree(props.blockObj.links)}
                        />}
                    </ul>
                </div>
            </BlockScroller>
        )
    } catch (error) {
        return <ErrorBlock blockObj={{errorMessage: error}} />
    }
})

export default QuicklinkBlock;