import styles from './styles/QuicklinkBlock.module.scss';
import FontAwesome from "../../../utilities/FontAwesome";
import NavigationLink from "../../../header/navigation/NavigationLink";

const LinkItem = (props: {
    title: string,
    link: Navigation,
    icon: string,
}) => {
    const {title, icon, link} = props;

    return (
        <li className={styles.linkItem}>
            <FontAwesome fixedWidth name={icon} />
            <span><NavigationLink href={link.url || link.external_url || ''} navigationObj={link}>{title}</NavigationLink></span>
        </li>
    )
};

export default LinkItem;
